














































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import axios from 'axios'
import { EventBus } from '@/utils/event-bus'
import { saveAs } from 'file-saver'
import { lookup } from 'mime-types'

@Component
export default class FAQs extends Vue {
  breadcrumbItems: any = [
    {
      text: 'Home',
      href: '/'
    },
    {
      text: 'FAQs',
      href: '#'
    }
  ];

  async downloadFile (file: any) {
    EventBus.$emit('show-loader')
    const resp = await axios.get(`resources/download/${file.key}`, { withCredentials: true, responseType: 'blob' })
    if (!resp || resp.status !== 200) {
      EventBus.$emit('hide-loader')
      return
    }
    const _blob = new Blob([resp.data], { type: lookup(file.filename as string) || undefined })
    saveAs(_blob, file.filename)
    EventBus.$emit('hide-loader')
  }
}
