





























































import { Component, Vue } from 'vue-property-decorator'
import axios from 'axios'
import { EventBus } from '@/utils/event-bus'

@Component
export default class Contact extends Vue {
  breadcrumbItems: any = [
    {
      text: 'Home',
      href: '/'
    },
    {
      text: 'Contact',
      href: '#'
    }
  ]

  form: any = {
    subject: '',
    description: '',
    issue: null
  }

  issue: any[] = [
    { text: 'Select One', value: null },
    'Bug/error',
    'Content error/correction',
    'Request access to content (permission)',
    'Content update/addition suggestion',
    'New platform feature suggestion'
  ]

  alertMsg: string = ''
  alertType: string = 'success'
  dismissSecs: number = 5
  dismissCountDown: number = 0
  countDownChanged (dismissCountDown: number) {
    this.dismissCountDown = dismissCountDown
  }

  onSubmit () {
    EventBus.$emit('show-loader')
    axios.post('/contact', this.form, { withCredentials: true }).then((result) => {
      if (result.status !== 200) {
        EventBus.$emit('hide-loader')
        this.alertMsg = 'Form submission failed - please try again.'
        this.alertType = 'danger'
        this.dismissCountDown = this.dismissSecs
        return
      }
      setTimeout(() => {
        EventBus.$emit('hide-loader')
        this.alertMsg = 'Thank you for your message, someone will respond to your query as soon as possible.'
        this.alertType = 'success'
        this.dismissCountDown = this.dismissSecs
        this.resetForm()
      }, 1500)
    })
  }

  resetForm () {
    // Reset our form values
    this.form.subject = ''
    this.form.issue = null
    this.form.description = ''
  }
}
